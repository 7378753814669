%section {
  width: 100%;
}

.section_lv1 {
  @extend %section;
}

.section_lv2 {
  @extend %section;
}

.main {
  padding-top: 30px;
  padding-bottom: 60px;

  @include media(sm) {
    padding-top: 73px;
    padding-bottom: 110px;
  }

  &--section:not(:first-of-type) {
    margin-top: 105px;
    @include media(sm,max) {
      margin-top: 60px;
    }
  }
}

.mask-web-banner {
  max-width: 280px;
  margin: 30px auto 0;
  &:hover {
    opacity: .5;
    transition-duration: .5s;
  }
}


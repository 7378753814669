.breadcrumbs {
  padding-top: 10px;
  background-color: color(bg,yellow1);
  br {
    display: none;
  }
  &--list {
    display: flex;
    font-size: 1.2rem;
  }
  &--item {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-size: 1.2rem;
    &:first-child {
      padding-left: 0;
      &::before {
        content: '';
      }
    }
    &::before {
      position: absolute;
      top: 0;
      left: 4px;
      font-size: 1.2rem;
      content: '>';
    }
  }
  &--link:not(_) {
    @include hover;
  }
}

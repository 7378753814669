.__hidden {
  visibility: hidden !important;
}

.__none {
  display: none !important;
}
.__sp_none {
  @include media(sm,max) {
    display: none !important;
  }
}
.__pc_none {
  @include media(sm) {
    display: none !important;
  }
}
.__md_none {
  @include media(md,max) {
    display: none !important;
  }
}
.__lg_none {
  @include media(md) {
    display: none !important;
  }
}

.__font-red{
  color: color(text,red);
}

.__nowrap {
  white-space: nowrap;
}

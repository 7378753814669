.wp_content, .post-format {
  line-height: (27.2/16);
  p {
    margin-bottom: 1.5em;
  }
  strong {
    font-size: 1.4em;
    font-weight: 700;
  }
}

.gnav {
  @include media(sm,max) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: zindex(front);
    width: 77%;
    max-width: 290px;
    height: 100%;
    padding: 70px 0 40px;
    background: color(bg,red);
    transition: transform .5s ease-out;
    transform: translateX(100%);
    &.is-open {
      transform: translateX(0);
    }
  }
  &--inner {
    @include media(sm,max) {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.gnav_list {
  $this: &;
  position: relative;
  @include media(md,max) {}
  @include media(sm,max) {
  }
  &--list {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(6,auto);
    box-shadow: 0 3px 8px rgba(color(bg,black),.16);
    @include overflow(true);
    @include media(md,max) {}
    @include media(sm,max) {
      display: block;
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
  }
  &--item {
    position: relative;
    @include font(ja,bold);
    @include media(md,max) {}
    @include media(sm,max) {}
    &:not(:first-of-type)::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 1px;
      height: 50%;
      content: '';
      background: color(bd,red);
      transform: translateY(-50%);
      @include media(md,max) {
        height: 60%;
      }
      @include media(sm,max) {
        display: none;
      }
    }
    &::after {
      @include media(sm,max) {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        content: '';
        background: color(bd,pink);
      }
    }
  }
  &--link {
    position: relative;
    height: 100%;
    padding: 17px - margin(1.6,16) 25px 17px - margin(1.6,16);
    font-size: 1.6rem;
    line-height: 1.6;
    color: color(text,black);
    @include flex;
    @include media(md,max) {
      padding: 17px - margin(1.6,16) 12px 17px - margin(1.6,16);
      font-size: 1.4rem;
      text-align: center;
      @include flex;
    }
    @include media(sm,max) {
      justify-content: flex-start;
      padding: 28px - margin(1.6,23) 25px;
      font-size: 2.3rem;
      color: color(text,white);
      text-align: left;
    }
    > br {
      display: none;
      @include media(md,max) {
        display: inline;
      }
      @include media(sm,max) {
        display: none;
      }
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 6px;
      content: '';
      background-color: rgba(color(bd,red),0);
      @include media(md,max) {}
      @include media(sm,max) {}
    }
    @include hover {
      @include media(md) {
        color: color(text,red);
      }
      @include media(md,max) {}
      @include media(sm,max) {
        content: color(text,white);
      }
      &::before {
        background-color: color(bd,red);
        @include media(md,max) {}
        @include media(sm,max) {}
      }
    }
    &.is-current {
      @include hover {
        @include media(md) {
          color: color(text,red);
        }
        @include media(md,max) {}
        @include media(sm,max) {
          content: color(text,white);
        }
        &::before {
          background-color: color(bd,red);
          @include media(md,max) {}
          @include media(sm,max) {}
        }
      }
    }
  }
}

.gnav_banner {
  display: none;
  @include media(md,max) {}
  @include media(sm,max) {
    display: block;
    padding: 0 20px 50px;
    margin-top: 45px;
  }
  &--list {
    @include media(md,max) {}
    @include media(sm,max) {}
  }
  &--item {
    background: color(gra,orange);
    @include media(md,max) {}
    @include media(sm,max) {
      &:not(:first-of-type) {
        margin-top: 15px;
      }
    }
  }
  &--link {
    position: relative;
    @include flex;
    flex-direction: column;
    height: 50px;
    color: color(text,black);
    @include hoverimg;
    @include media(md,max) {}
    @include media(sm,max) {}
  }
  &--text {
    font-size: 1.3rem;
    font-weight: 700;
    @include media(md,max) {}
    @include media(sm,max) {}
  }
  &--icon {
    height: 24px;
    @include media(md,max) {}
    @include media(sm,max) {}
    > use {
      fill: color(text,black);
      @include media(md,max) {}
      @include media(sm,max) {}
    }
  }
}

.gnav_contact {
  display: none;
  line-height: 1;
  color: color(text,white);
  letter-spacing: 0;
  @include media(sm,max) {
    display: block;
    margin-top: 10px;
  }
  &--heading {
    font-weight: 700;
    @include media(sm,max) {
      font-size: 1.3rem;
    }
  }
  &--text {
    @include media(sm,max) {
      font-size: 1rem;
    }
    &:first-of-type {
      @include media(sm,max) {
        margin-top: 10px;
      }
    }
    &:not(:first-of-type) {
      @include media(sm,max) {
        margin-top: 2px;
      }
    }
  }
}

// //インナーの設定
// //jsによって生成されます。
.__gnavInner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: zindex(modal,main);
  display: none;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  background: rgba(#000, .3);
  &.is-open {
    display: block;
    pointer-events: auto;
    animation: fadeIn .5s linear forwards;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  margin-left: 5px;
  background: color(bg,white);
  opacity: .8;
}

.swiper-pagination-bullet-active {
  background: color(bg,gold);
}

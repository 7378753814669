/* Pluguins
==================== */
//@import 'vendor/*\.css\.scss';
@import 'vendor/swiper.css.scss';

/* Modules
==================== */
@import '@foundation/_fonts';
@import '@foundation/_foundation';
@import '@foundation/_@base';
//@import 'layout/**';
@import 'layout/404';
@import 'layout/@footer';
@import 'layout/@header';
@import 'layout/@sections';
@import 'layout/access';
@import 'layout/banner';
@import 'layout/breadcrumbs';
@import 'layout/col';
@import 'layout/gnav';
@import 'layout/hamburger';
@import 'layout/hero';
@import 'layout/news-common';
@import 'layout/pagenation';

//@import 'vendor/!(*\.css\.scss)';
@import 'vendor/swiper';

//@import 'object/**';
@import 'object/@button';
@import 'object/@heading';

//@import 'utility/**';
@import 'utility/@modifier';
@import 'utility/fonts';
@import 'utility/margin';
@import 'utility/padding';
@import 'utility/text-align';

@import 'wppost/_wppost';
@import 'wppost/chrome';

/* Style
==================== */
@import 'page/style.scss';

/* Overwrites
==================== */

$burgerSize: 50;
$line: 3;

.burger {
  position: fixed;
  top: 4px;
  right: 21px;
  z-index: zindex(ham);
  display: none;
  width: px($burgerSize);
  height: px($burgerSize);
  cursor: pointer;
  @include media(sm,max) {
    display: block;
    width: 50px;
    height: 55px;
    background: color(bg,red);
    border-radius: 3px;
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 700;
    color: color(text,white);
    letter-spacing: 0;
    content: 'メニュー';
    transform: translateX(-50%);
  }
  > svg {
    fill: color(bg,white);
  }
  &--line {
    &._line1 {
      transition: transform .5s;
      transform: rotate(0) translateY(-14px);
      transform-origin: 50%;
      @if ($line == 2) {
        transform: rotate(0) translateY(-7.5px);
      }
    }
    &._line2 {
      transition: transform .5s;
      transform: rotate(0) translateY(6px);
      transform-origin: 50%;
      @if ($line == 2) {
        transform: rotate(0) translateY(7.5px);
      }
    }
    &._line3 {
      transition: fill .3s ease-in-out;
      transform: rotate(0) translateY(-4px);
    }
  }
}

.burger[aria-expanded='true'] {
  &::before {
    content: '閉じる';
  }
  .burger {
    &--line {
      &._line1 {
        transform: rotate(45deg) translateY(0);
      }
      &._line2 {
        transform: rotate(-45deg) translateY(0);
      }
      &._line3 {
        fill: rgba(#fff, 0);
      }
    }
  }
}

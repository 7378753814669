// //上下のマージン

.__mt_auto {
  margin-top: auto !important;
}

@for $i from 0 through 18 {
  .__mt_#{$i} {
    margin-top: rem($i,5) !important;
  }
  .__mb_#{$i} {
    margin-bottom: rem($i,5) !important;
  }
  .__my_#{$i} {
    margin-top: rem($i,5) !important;
    margin-bottom: rem($i,5) !important;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include media($bp) {
    @for $i from 0 through 18 {
      .__my_#{$bp}_#{$i} {
        margin-top: rem($i,5) !important;
      }
      .__mt_#{$bp}_#{$i} {
        margin-top: rem($i,5) !important;
      }
      .__mb_#{$bp}_#{$i} {
        margin-bottom: rem($i,5) !important;
      }
    }
  }
}

// //左右のマージン

// .mx-auto {
//   margin-right: auto !important;
//   margin-left: auto !important;
// }

// .ml-auto {
//   margin-left: auto !important;
// }

// .mr-auto {
//   margin-right: auto !important;
// }

// @for $i from 0 through 18 {
//   .mx-#{$i} {
//     margin-right: rem($i,5) !important;
//     margin-left: rem($i,5) !important;
//   }
//   .ml-#{$i} {
//     margin-left: rem($i,5) !important;
//   }
//   .mr-#{$i} {
//     margin-right: rem($i,5) !important;
//   }
// }

// @each $bp, $breakpoint in $breakpoints {
//   @include media($bp) {
//     @for $i from 0 through 18 {
//       .mx-#{$bp}-#{$i} {
//         margin-right: rem($i,5) !important;
//         margin-left: rem($i,5) !important;
//       }
//       .ml-#{$bp}-#{$i} {
//         margin-left: rem($i,5) !important;
//       }
//       .mr-#{$bp}-#{$i} {
//         margin-right: rem($i,5) !important;
//       }
//     }
//   }
// }

.header {
  display: flex;
  align-items: flex-end;
  height: 120px;
  margin-bottom: 15px;
  @include overflow(ture);
  border-top: 10px solid color(bg,red);
  @include media(md,max) {
    align-items: flex-start;
    height: auto;
  }
  @include media(sm,max) {
    align-items: center;
    height: 72px;
    margin-bottom: 0;
    background: color(bg,red);
    border-top: 0;
  }
}

.header_logo {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 100%;
  max-width: 160px;
  background-color: color(bg,red);
  @include media(md,max) {
    max-width: (160px * $BREAK_POINT_MD_RATE);
    margin-bottom: auto;
  }
  @include media(sm,max) {
    max-width: 80px;
    margin-bottom: 0;
  }
  &::before {
    position: absolute;
    top: -10px;
    right: 1px;
    z-index: zindex(back);
    display: block;
    width: 145px;
    height: calc(100% + 15.5px);
    pointer-events: none;
    content: '';
    background: url(../img/common/header.svg) no-repeat center right /cover;
    transform: translateX(100%);
    @include media(md,max) {
      right: 2px;
      width: (145px * $BREAK_POINT_MD_RATE);
    }
    @include media(sm,max) {
      display: none;
    }
  }
  &::after {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: zindex(back);
    display: block;
    width: 50vw;
    height: 100%;
    content: '';
    background: color(bg,red);
    transform: translateX(-50vw);
    @include media(md,max) {}
    @include media(sm,max) {}
  }
}

.header_catch {
  padding-bottom: 10px - margin((24/18),18);
  margin-left: 95px;
  @include media(md,max) {
    padding-top: (10px * $BREAK_POINT_MD_RATE);
    padding-bottom: 0;
    margin-left: (95px * .6);
  }
  @include media(sm,max) {
    display: none;
  }
  &--title {
    font-size: 3.8rem;
    font-weight: 700;
    line-height: 1;
    @include media(md,max) {
      font-size: 1.2rem;
      letter-spacing: 0;
    }
    @include media(sm,max) {}
  }
  &--text {
    margin-top: 25px - margin((24/18),18);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: (24/18);
    color: color(text,red);
    @include media(md,max) {
      margin-top: ((25px - margin((24/18),18)) * $BREAK_POINT_MD_RATE);
      font-size: (1.8rem * .88);
    }
    @include media(sm,max) {}
  }
}

.header_contact {
  padding-bottom: 10px;
  margin-left: auto;
  font-size: 1.2rem;
  line-height: 1;
  @include media(md,max) {
    padding-top: (10px * $BREAK_POINT_MD_RATE);
    padding-bottom: 0;
    font-size: (1.2rem * $BREAK_POINT_MD_RATE);
  }
  @include media(sm,max) {
    display: none;
  }
  &--heading {
    font-weight:bold;
  }
  &--text {
    &._bold {
      font-weight: 700;
      @include media(md,max) {}
      @include media(sm,max) {}
    }
  }
  &--link {
    display: block;
    margin-top: 4px;
    margin-bottom: 2px;
    font-size: 2.7rem;
    font-weight: 700;
    color: color(text,red);
    @include media(md,max) {
      font-size: (2.7rem * $BREAK_POINT_MD_RATE);
    }
    @include media(sm,max) {}
  }
}

// ヘッダーに追加した問い合わせ

.headAccess {
  width: 100%;
  max-width: 85px;
  height: 100%;
  margin-right: 20%;
  margin-left: auto;
  background: color(gra,orange);

  @include media(sm) {
    display: none;
  }

  &--link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &--icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;

    > use {
      fill: color(text,white);
    }
  }

  &--text {
    font-size: 1.1rem;
    color: color(text,white);
  }
}

//pagination
.blog-pager {
  display: flex;
  justify-content: center;
  .pager--item {
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 25px;
      height: 30px;
      padding-left: 1px;
      line-height: 1;
      color: color(text,black);
      border: 1px solid color(bd,red);
      &.back-list__btn {
        padding-right: 1em;
        padding-left: 1em;
      }
      &.active {
        color: color(text,white);
        background: color(bg,red);
      }
      @include hover {
        color: color(text,white);
        background: color(bg,red);
        .prev-triangle__btn::before, .next-triangle__btn::before {
          border-color: color(bd,white);
        }
      }
    }
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
  .prev-triangle__btn, .next-triangle__btn {
    position: relative;
    &::before {
      position: absolute;
      top: 49%;
      left: 40%;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      transform: translate(-50%,-50%) rotate(45deg);
    }
  }
  .prev-triangle__btn {
    &::before {
      left: 62%;
      border-bottom: 1px solid color(bd,red);
      border-left: 1px solid color(bd,red);
    }
  }
  .next-triangle__btn {
    &::before {
      border-top: 1px solid color(bd,red);
      border-right: 1px solid color(bd,red);
    }
  }
}

%heading {
  @include font(Ja,bold);
}

.heading-lv1 {
  @extend %heading;
  @extend .inner;
  padding-top: (8px - margin((43/28),28));
  padding-bottom: (8px - margin((43/28),28));
  padding-left: 16px;
  margin-bottom: 50px;
  color: color(text,red);
  border-left: 11px solid color(bd,red);
  @include media(sm) {
    margin-bottom: 43px;
  }
  &__inner {
    font-size: 2.8rem;
    line-height: (43/28);

    > ._black {
      font-size: 1.8rem;
      color: color(text,black);
    }
  }
}

// - margin((30.6/18),18)
.heading-lv2 {
  @extend %heading;
  font-size: 1.8rem;
  line-height: (30.6/18);
}

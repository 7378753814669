.news--category {
  position: relative;
  display: inline-block;
  padding: 0 1.2em;
  margin-right: 15px;
  margin-bottom: 8px;
  font-size: 1.3rem;
  border: 1px solid color(bd, red);
  transform: translateY(-1px);
  @include hoverimg;

  &._red {
    color: color(text, white);
    background-color: color(bg, red);
  }

  &._white {
    color: color(text, red);
    border: 1px solid color(bd, red);
  }
}


.footer {
  @include overflow(ture);
  padding-top: 20px;
  padding-bottom: 16px - margin((14/10),10);
  color: color(text,white);
  background-color: color(bg,red);
  @media (max-width: 1300px) {
    margin-bottom: 50px;
  }
  &--inner {
    display: grid;
    grid-template:
      'logo copy button' auto /
      98px 1fr 80px;
    //align-items: flex-end;
    > * {
      align-self: flex-end
    }
    @include media(sm) {
      grid-template:
        'logo nav' 80px
        'copy nav' auto /
        112px 1fr;
      //align-items: center;
      gap: 0 36.4px;
      > * {
        align-self: center
      }
    }
  }
  &--copy {
    @include font(en,regular);
    grid-area: copy;
    margin-top: 13px - margin((14/10),10);
    font-size: 1rem;
    line-height: (14/10);
    @include media(sm, max) {
      margin-left: 50px;
    }
    @include media(xs, max) {
      margin-left: 10px;
    }
  }
  &--logo {
    grid-area: logo;
  }
}
.footer_nav {
  display: none;
  @include media(sm) {
    display: flex;
    flex-wrap: wrap;
    grid-area: nav;
    align-items: center;
    justify-content: center;
    margin-left: -50px;
    font-size: 1.2rem;
    @include font(Ja,bold);
    &--list {
      margin-left: 50px;
    }
    a {
      line-height: (30/12);
      color: inherit;
    }
  }
}

//Topへ戻るボタン
.top_btn {
  display: none;
  @include media(sm,max) {
    display: block;
    grid-area: button;
    &--inner {
      display: block;
      width: 79.6px;
      height: 78.2px;
      background-image: url(../img/common/boo.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &--img {
      width: 65%;
      padding-top: 20px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

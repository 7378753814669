.banner {
  position: fixed;
  top: 35px;
  right: 0;
  z-index: zindex(banner);
  display: block;
  // width: 135px;
  width: 150px;

  @include media(md, max) {
    top: auto;
    bottom: 0;
    width: 100%;
  }

  &--list {
    @include media(md, max) {
      display: flex;
      width: 100%;
    }
  }

  &--item {
    background: color(gra, orange);

    @include media(md, max) {
      width: calc(100% / 4);
    }

    &._tel {
      position: relative;
      width: 74.2%;
      background: color(gra, orange);

      @include media(sm) {
        width: 50%;
      }
      @include media(md) {
        width: 100%;
      }

      &::before {
        position: absolute;
        top: 4px;
        left: 4px;
        display: block;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        content: '';
        background: color(bg, white);
      }

      .banner--link {
        @include media(md, max) {
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 26% 1fr;
        }
      }

      .banner--text {
        color: color(text, black);

        @include media(md, max) {
          font-size: 2.2rem;
        }
      }

      .banner--icon {
        width: 24px;
        height: 24px;
        margin: 0 auto;
      }
    }
  }

  &--link {
    position: relative;
    @include flex;
    flex-direction: column;
    height: 50px;
    color: color(text, black);

    @include hoverimg;

    @include media(md, max) {}

    @include media(sm, max) {}


  }

  &--text {
    font-size: 1.3rem;
    font-weight: 700;
    color: color(text, white);

    @include media(md, max) {}

    @include media(sm, max) {}
  }

  &--icon {
    width: 24px;
    height: 24px;

    @include media(md, max) {}

    @include media(sm, max) {}

    > use {
      fill: color(text, white);

      @include media(md, max) {}

      @include media(sm, max) {}
    }
  }

  &--link--mask_banner {
    @include flex;
    flex-direction: column;
    &:hover {
      opacity: .5;
      transition-duration: .5s;
    }

  }
}

// //上下のパディング
@for $i from 0 through 18 {
  .__pt_#{$i} {
    margin-top: rem($i,5) !important;
  }
  .__pb_#{$i} {
    margin-bottom: rem($i,5) !important;
  }
  .__py_#{$i} {
    margin-top: rem($i,5) !important;
    margin-bottom: rem($i,5) !important;
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include media($bp) {
    @for $i from 0 through 18 {
      .__py_#{$bp}_#{$i} {
        margin-top: rem($i,5) !important;
      }
      .__pt_#{$bp}_#{$i} {
        margin-top: rem($i,5) !important;
      }
      .__pb_#{$bp}_#{$i} {
        margin-bottom: rem($i,5) !important;
      }
    }
  }
}

// //左右のパディング

// @for $i from 0 through 18 {
//   .px-#{$i} {
//     margin-right: rem($i,5) !important;
//     margin-left: rem($i,5) !important;
//   }
//   .pl-#{$i} {
//     margin-left: rem($i,5) !important;
//   }
//   .pr-#{$i} {
//     margin-right: rem($i,5) !important;
//   }
// }

// @each $bp, $breakpoint in $breakpoints {
//   @include media($bp) {
//     @for $i from 0 through 18 {
//       .px-#{$bp}-#{$i} {
//         margin-right: rem($i,5) !important;
//         margin-left: rem($i,5) !important;
//       }
//       .pl-#{$bp}-#{$i} {
//         margin-left: rem($i,5) !important;
//       }
//       .pr-#{$bp}-#{$i} {
//         margin-right: rem($i,5) !important;
//       }
//     }
//   }
// }

@import './reset';
@import './_animation.scss';

:root {
  //htmlにブレークポイント表示用
  --breakpoint-xs: #{px(map-get($breakpoints, xs ))};
  --breakpoint-sm: #{px(map-get($breakpoints, sm ))};
  --breakpoint-md: #{px(map-get($breakpoints, md ))};
  --breakpoint-lg: #{px(map-get($breakpoints, lg ))};
  //フォント
  //--font-ja-sans: #{font(ja,sans)};
  // --font-ja-serif: #{font(ja,serif)};
  //--font-en-sans: #{font(en,sans)};
  // --font-en-serif: #{font(en,serif)};
}

body {
  position: relative;
  font-size: 1.6rem;
  line-height: (27.2/16);
  @include font(Ja,regular);
  // windowsのIE11のみメイリオにフォントを変える
  &.ie.windows {
    font-family: 'メイリオ', Meiryo, sans-serif;
  }
  @at-root _:-ms-fullscreen,:root & {
    font-family: 'メイリオ', Meiryo, sans-serif;
  }
}

.wrapper {
  //innerがサイドタッチしないように予めスマホ時の左右padding分設定
  max-width: px($inner-width + ($wrapper-padding * 2));
  padding: 0 px($wrapper-padding) 0;
  margin: 0 auto;
  .inner {
    max-width: 1030px;
    margin-right: auto;
    margin-left: auto;
  }
}

a{
  color: color(text,black);
}

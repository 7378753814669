.page-404 {
  .main {
    padding-top: 60px;
    text-align: center;
    @include media(sm) {
      padding-top: 133px;
    }
  }
}

.page404 {
  &--text {
    margin: 20px 0;
    font-size: 1.6rem;
    color: color(text, red);
  }
  &--button {
    position: relative;
    display: block;
    width: 100%;
    max-width: 120px;
    padding: 7px 0;
    margin: 0 auto;
    @include font(Ja, bold);
    line-height: 1.68;
    color: color(text, white);
    background-color: color(bg, red);
    border-radius: 4px;
    @include hover {
      color: color(text, white);
    }
    @include hoverimg;
  }
}

.img404 {
  fill: color(text,red);
}


.hero {
  @include overflow(ture);
  @extend %heading;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 123px;
  background-image: color(gra,red);
  @include media(sm) {
    height: 183px;
  }
  &__title {
    font-size: 2.5rem;
    line-height: (33/25);
    color: color(text,white);
    text-align: center;
  }
}

.access {
  font-size: 1.4rem;
  line-height: (21/14);
  &--title {
    @include media(sm,max) {
      margin-bottom: 20px;
    }
  }
  &--map {
    @include overflow(true);
    position: relative;
    height: 0;
    padding-bottom: 230px;
    @include media(sm) {
      padding-bottom: 348px;
    }
    /* stylelint-disable */
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
    /* stylelint-enable */
  }
  &--address {
    margin-top: 8px;
    font-size: 1.9rem;
    line-height: (29/20);
  }
  &--postcode {
    font-size: 1.5rem;
    line-height: (29/15);
  }
  &--photo2 {
    margin-left: 20px;
  }
}
.access_area {
  display: grid;
  grid-template:
    'text' auto
    'tup' auto/
    100%;
  justify-items: center;
  max-width: 515px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  @include media(md) {
    grid-template:
      'text tup' auto/
      515px 1fr;
    max-width: inherit;
    padding-bottom: 30px;
  }
  &--text {
    grid-area: text;
  }
  &--tup {
    grid-area: tup;
    > a {
      display: block;
      max-width: 217px;
      margin: 15px auto 0;
      @include media(md) {
        margin: 52px 0 0;
      }
    }
  }
}
.access_shop {
  display: grid;
  grid-template:
    'head head' auto
    'name shop' auto;
  //align-items: baseline;
  margin-top: 20px;
  > * {
    align-self: baseline
  }
  @include media(xs,max) {
    grid-template:
      'head head' auto
      'name shop' auto;
  }
  @include media(md) {
    margin-top: 52px;
  }
  &--head {
    display: block;
    grid-area: head;
    font-size: 1.5rem;
    line-height: (28/15);
  }
  &--name {
    display: block;
    grid-area: name;
    font-size: 3.4rem;
    line-height: (45/34);
    @include font(Ja,bold);
    @include media(xs,max) {
      font-size: 3rem;
    }
  }
  &--shop {
    display: block;
    grid-area: shop;
    align-items: flex-end;
    padding-left: 22px;
    font-size: 1.9rem;
    line-height: (29/19);
    @include media(xs,max) {
      font-size: 1.4rem;
    }
  }
}

.info {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 74px 1fr;
  margin-top: 15px;
  font-size: 1.6rem;
  line-height: (21/14);
  &--head {
    font-size: 1.5rem;
    &.__center {
      align-self: center;
    }
  }
  &--text {
    margin-top:  0 - margin((21/14),14);
    &.__center {
      align-self: center;
    }
  }
  //個別設定
  &--parking {
    margin-bottom: 20px - margin((21/14),14);
  }
  &_tel {
    margin-top: 0;
    p {
      line-height: 1;
    }
    &--link {
      font-size: 2rem;
      color: color(text,black);
      letter-spacing: 0;
      @include font(ja,bold);
      @include media(xs,max) {
        font-size: 2rem;
      }
    }
  }
  &_time {
    margin-bottom: 30px;
    @include media(md) {
      margin-bottom: 20px;
    }
    &--text {
      margin-top: 1.6rem;
      font-size: 2rem;
      line-height: (21/19);
      letter-spacing: 0;
      @include media(xs,max) {
        font-size: 1.6rem;
      }
    }
    &--booking {
      margin-top: 15px;
      font-size: 1.9rem;
      line-height: (21/19);
      text-decoration: underline;
      @include media(md) {
        margin-top: 24px;
      }
    }
  }
  &_dayoff {
    &--text {
      font-size: 1.8rem;
      @include media(xs,max) {
        font-size: 1.5rem;
      }
      @include media(md) {
        font-size: 1.9rem;
      }
      &.__small {
        font-size: 1.4rem;
        letter-spacing: 0;
        @include media(xs,max) {
          font-size: 1.1rem;
        }
      }
    }
  }
}
